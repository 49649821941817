import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";

import HeaderWithTextAndImage from "../../components/headerWithTextAndImage";
import TextWrapAroundImg from "../../components/textWrapAroundImg";

import caretRight from "../../images/caret-right.png";
import genericUser from "../../images/genericUser.png";
import Head from "../../components/head";
import TssUgaStyles from "./tssUga.module.css";

import headlineStyles from "../../components/headline.module.css";

import HeroHeaderStyles from "../../components/HeroHeader.module.css";

export const query = graphql`
  query {
    ugaProfessor: allContentfulBio(
      filter: { program: { eq: "UGA Professor" } }
    ) {
      edges {
        node {
          name
          titleOrInstrument
          bioImage {
            file {
              url
            }
          }
        }
      }
    }
    ugaProfessorBOD: allContentfulBio(
      filter: { program: { eq: "UGA Professor and Georgia BOD" } }
    ) {
      edges {
        node {
          name
          titleOrInstrument
          bioImage {
            file {
              url
            }
          }
        }
      }
    }
    ugaScholar: allContentfulBio(filter: { program: { eq: "UGA Scholar" } }) {
      edges {
        node {
          bioImage {
            file {
              url
            }
          }
          name
          titleOrInstrument
        }
      }
    }

    allContentfulTssUgaPage {
      edges {
        node {
          headerImage {
            file {
              url
            }
          }
          headerHeadline {
            headerHeadline
          }
          headerSubheadline {
            headerSubheadline
          }
          section1Image {
            file {
              url
            }
          }
          section1Headline {
            section1Headline
          }
          section1Paragraph {
            section1Paragraph
          }
          section2Image {
            file {
              url
            }
          }
          section2Headline
          section2Paragraph {
            section2Paragraph
          }
          section3Image {
            file {
              url
            }
          }
          section3Headline
          section3Paragraph {
            section3Paragraph
          }
          ugaScholarsHeadline
          ugaProfessorsHeadline
        }
      }
    }
  }
`;

class TssUga extends React.Component {
  componentDidMount() {
    Array.from(document.querySelectorAll("p")).forEach(el => {
      let v = el.innerHTML;
      let p1 = v.replace(
        "Better Music, Better World.",
        "<strong style=color:#ba0c2f; font-weight:600>Better Music, Better World.</strong>"
      );
      el.innerHTML = p1;
    });

    Array.from(document.querySelectorAll("p")).forEach(el => {
      let v = el.innerHTML;
      // var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      let link = v.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + "</a>";
      });
      el.innerHTML = link;
    });
  }
  render() {
    let arr = [];
    arr.push(...this.props.data.ugaProfessorBOD.edges);
    arr.push(...this.props.data.ugaProfessor.edges);
    return (
      <>
        <Head title="TSS training | UGA" />
        <section>
          <div
            className={HeroHeaderStyles.backgroundImageContainer}
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${this.props.data.allContentfulTssUgaPage.edges[0].node.headerImage.file.url})`,
            }}
          >
            <div className={HeroHeaderStyles.heroContainer}>
              <h1 className={HeroHeaderStyles.heading}>
                {
                  this.props.data.allContentfulTssUgaPage.edges[0].node
                    .headerHeadline.headerHeadline
                }
              </h1>
              <p className={HeroHeaderStyles.subHeading}>
                {
                  this.props.data.allContentfulTssUgaPage.edges[0].node
                    .headerSubheadline.headerSubheadline
                }
              </p>
              <div className={HeroHeaderStyles.buttonContainer}>
                <Link to="/donate" className={HeroHeaderStyles.primaryBtn}>
                  Help Us
                </Link>
              </div>
            </div>
          </div>
        </section>
        <div
          className={headlineStyles.headline}
          style={{ backgroundColor: "#fff" }}
        >
          <h2>
            {
              this.props.data.allContentfulTssUgaPage.edges[0].node
                .section1Headline.section1Headline
            }
          </h2>
        </div>
        <HeaderWithTextAndImage
          paragraphHeader={false}
          imgRight={
            this.props.data.allContentfulTssUgaPage.edges[0].node.section1Image
              .file.url
          }
          isFirstElement={false}
          lightBackground={true}
        >
          {" "}
          <p style={{ whiteSpace: "pre-wrap" }}>
            {
              this.props.data.allContentfulTssUgaPage.edges[0].node
                .section1Paragraph.section1Paragraph
            }
          </p>
        </HeaderWithTextAndImage>
        <TextWrapAroundImg
          imgRight={
            this.props.data.allContentfulTssUgaPage.edges[0].node.section2Image
              .file.url
          }
          paragraphHeader={
            this.props.data.allContentfulTssUgaPage.edges[0].node
              .section2Headline
          }
          lightBackground={true}
        >
          <p style={{ whiteSpace: "pre-wrap" }}>
            {
              this.props.data.allContentfulTssUgaPage.edges[0].node
                .section2Paragraph.section2Paragraph
            }
          </p>
        </TextWrapAroundImg>

        <HeaderWithTextAndImage
          paragraphHeader={
            this.props.data.allContentfulTssUgaPage.edges[0].node
              .section3Headline
          }
          imgRight={
            this.props.data.allContentfulTssUgaPage.edges[0].node.section3Image
              .file.url
          }
          isFirstElement={false}
          lightBackground={true}
        >
          {" "}
          <p>
            {
              this.props.data.allContentfulTssUgaPage.edges[0].node
                .section3Paragraph.section3Paragraph
            }
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Link
              to="/donate"
              style={{
                textDecoration: "none",
                color: "#ba0c2f",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
              }}
            >
              Help us
              <img
                src={caretRight}
                style={{ height: "9px", marginLeft: "4px", width: "auto" }}
              ></img>
            </Link>
          </div>
        </HeaderWithTextAndImage>

        <section className={TssUgaStyles.UgaScholarSection}>
          <div className={TssUgaStyles.UgaScholarContainer}>
            <h3>
              {
                this.props.data.allContentfulTssUgaPage.edges[0].node
                  .ugaScholarsHeadline
              }
            </h3>
            <div>
              {this.props.data.ugaScholar.edges.map((edge, index) => {
                return (
                  <div
                    key={`ugaScholar${index}`}
                    className={TssUgaStyles.scholar}
                  >
                    <Link to={`/bio/${edge.node.name}`}>
                      <div>
                        {edge.node.bioImage === null ? (
                          <img src={genericUser}></img>
                        ) : (
                          <img src={edge.node.bioImage.file.url}></img>
                        )}
                        <div>
                          <h4>{edge.node.name}</h4>
                          <p>{edge.node.titleOrInstrument}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section className={TssUgaStyles.UgaScholarSection}>
          <div className={TssUgaStyles.UgaProfessorContainer}>
            <h3>
              {
                this.props.data.allContentfulTssUgaPage.edges[0].node
                  .ugaProfessorsHeadline
              }
            </h3>
            <div>
              {arr.map((edge, index) => {
                return (
                  <div
                    key={`ugaProfesssor${index}`}
                    className={TssUgaStyles.scholar}
                  >
                    <Link to={`/bio/${edge.node.name}`}>
                      <div>
                        <img src={edge.node.bioImage.file.url}></img>
                        <div>
                          <h4>{edge.node.name}</h4>
                          <p>{edge.node.titleOrInstrument}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default TssUga;
